import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import {
  Field,
  FormSection,
  formValues,
  SubmissionError,
  propTypes as formPropTypes,
} from 'redux-form'
import { lpForm } from 'lp-form'
import { addYears } from 'date-fns'
import {
  Checkbox,
  Input,
  MaskedInput,
  RadioGroup,
  Select,
  SubmitButton,
} from 'lp-components'
import { SearchResultsPanel } from 'components'
import { AutomaticRenewalWarningModal } from 'member-portal/components'
import {
  ADDRESS_FORM_VALIDATOR,
  COUNTRY,
  SEATOW_INFO_CONTACT_EMAIL,
  SEATOW_PHONE,
  SEATOW_PHONE_DIGITS,
  USER_RULES,
} from 'config'
import { DONATION_CONTRIBUTION_AMOUNTS } from 'types'
import {
  ccExpirationDateAfterProvidedDate,
  formatCreditCardExpirationDate,
  formatISODate,
  getLocalityFromGooglePlace,
  parseNumberFromString,
  removeNonDigits,
  stateListFromCountry,
  useToggle,
  formatSubpremise,
} from 'utils'
import { debounce } from 'lodash'

const propTypes = {
  ...formPropTypes,
  autoCompleteAddress: PropTypes.func.isRequired,
  billingAddressSameAsShipping: PropTypes.bool,
  country: PropTypes.string,
  donationAmounts: PropTypes.string,
  fetchPlaceDetails: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isTrialMembershipsActive: PropTypes.bool,
  otherDollarDonationAmount: PropTypes.number,
  showAutoRenew: PropTypes.bool.isRequired,
  showDonationForm: PropTypes.bool.isRequired,
  showReferral: PropTypes.bool.isRequired,
  totalPayment: PropTypes.number,
}

function PaymentForm({
  autoCompleteAddress,
  autoRenewalAgreement,
  billingAddressSameAsShipping,
  change,
  country,
  donationAmounts,
  fetchPlaceDetails,
  handleSubmit,
  initialValues,
  isTrialMembershipsActive,
  otherDollarDonationAmount,
  promoCodeIsTrial,
  showAutoRenew,
  showDonationForm,
  showReferral,
  submitting,
  totalPayment,
}) {
  const [autoRenewalModalDisplayed, toggleAutoRenewalModalDisplay] =
    useToggle(false)
  const [searchResults, updateSearchResults] = useState(null)
  const [resultsPanelOpen, setResultsPanelOpen] = useState(false)
  const closeResultsPanel = () => setResultsPanelOpen(false)
  const openResultsPanel = () => setResultsPanelOpen(true)
  const [showMemberName, setShowMemberName] = useState(false)

  const updateAddressValues = useCallback(async (searchResult) => {
    const googlePlace = await fetchPlaceDetails(searchResult.place_id)
    const {
      streetNumber,
      street,
      city,
      state,
      country,
      postalCode,
      subpremise,
    } = getLocalityFromGooglePlace(googlePlace)

    if (subpremise) {
      const formattedSubpremise = formatSubpremise(subpremise)
      change(
        'account.address',
        `${streetNumber} ${street}, ${formattedSubpremise}`,
      )
    } else {
      change('account.address', `${streetNumber} ${street}`)
    }
    change('account.city', city)
    change('account.state', state)
    change('account.country', country)
    change('account.postalCode', postalCode)
  }, [])

  const showCCForm = () => {
    if (autoRenewalAgreement) return true
    if (
      donationAmounts === DONATION_CONTRIBUTION_AMOUNTS.OTHER &&
      otherDollarDonationAmount <= 0
    )
      return false
    if (
      donationAmounts &&
      donationAmounts !== DONATION_CONTRIBUTION_AMOUNTS.NO_DONATION
    )
      return true
    return !(totalPayment === 0)
  }

  const {
    fetchedReferrerMemberName,
    fetchedReferrerMemberNumber,
    referredByMemberNumber,
  } = initialValues ?? {}

  const handleMemberNumberChange = (e) => {
    const { value } = e.target
    setShowMemberName(
      fetchedReferrerMemberNumber && value === fetchedReferrerMemberNumber,
    )
  }

  useEffect(() => {
    if (!fetchedReferrerMemberNumber) return

    setShowMemberName(referredByMemberNumber === fetchedReferrerMemberNumber)
  }, [referredByMemberNumber, fetchedReferrerMemberNumber])

  return (
    <>
      <AutomaticRenewalWarningModal
        isOpen={autoRenewalModalDisplayed}
        toggleModalOpen={toggleAutoRenewalModalDisplay}
      />
      {promoCodeIsTrial && isTrialMembershipsActive && <div></div>}

      <form noValidate onSubmit={handleSubmit}>
        {showReferral && (
          <div className="referred-by-wrapper">
            <div className="col-6">
              <Field
                className="referring-member-number"
                component={Input}
                label="Referred by a Sea Tow Member?"
                name="referredByMemberNumber"
                placeholder="Sea Tow member number"
                onChange={handleMemberNumberChange}
              />
              {showMemberName && (
                <span className="referring-member-name">
                  Name: {fetchedReferrerMemberName}
                </span>
              )}
            </div>
          </div>
        )}
        {showCCForm() ? (
          <>
            <div className="group-block">
              <div className="col-6">
                <Field
                  component={MaskedInput}
                  label="Credit Card Number"
                  maskOptions={{ creditCard: true }}
                  name="cardNumber"
                  placeholder="Credit card number (digits only)"
                  parse={removeNonDigits}
                  required
                  requiredIndicator="*"
                />
              </div>
              <div className="row">
                <div className="col-3">
                  <Field
                    component={MaskedInput}
                    label="Expiration"
                    maskOptions={{
                      date: true,
                      datePattern: ['m', 'y'],
                    }}
                    name="cardExpirationDate"
                    placeholder="MM/YY"
                    required
                    requiredIndicator="*"
                  />
                </div>
                <div className="col-3">
                  <Field
                    component={MaskedInput}
                    label="Security Code"
                    maskOptions={{
                      numeral: true,
                      numeralDecimalScale: 0,
                      numeralIntegerScale: 4,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'none',
                      stripLeadingZeroes: false,
                    }}
                    name="securityCode"
                    placeholder="CVV"
                    required
                    requiredIndicator="*"
                  />
                </div>
              </div>
            </div>
            <div>
              <Field
                component={Checkbox}
                label="Billing Address the Same as Mailing Address"
                name="billingAddressSameAsShipping"
              />

              {!billingAddressSameAsShipping && (
                <FormSection name="account">
                  <Field
                    component={Input}
                    label="Billing Address"
                    name="address"
                    onChange={debounce(async (_, newSearchQuery) => {
                      const normalizedSearchQuery = newSearchQuery
                        .split(' ')
                        .join('+')
                      const placeSearchResult = await autoCompleteAddress(
                        normalizedSearchQuery,
                      )

                      updateSearchResults(placeSearchResult.results)
                      openResultsPanel()
                    }, 350)}
                    required
                    requiredIndicator="*"
                  />
                  <SearchResultsPanel
                    closePanel={closeResultsPanel}
                    hideLocationName={true}
                    isPanelOpen={resultsPanelOpen}
                    searchResults={searchResults}
                    updateFormValues={updateAddressValues}
                  />
                  <div className="row">
                    <Field
                      component={Select}
                      name="country"
                      onChange={() => change('account.state', '')}
                      options={Object.values(COUNTRY)}
                      placeholder="Country"
                      required
                      requiredIndicator="*"
                      className="col-6"
                    />
                  </div>
                  <div className="row">
                    <Field
                      component={Input}
                      name="city"
                      required
                      requiredIndicator="*"
                      className="col-6"
                    />
                    <Field
                      component={Select}
                      name="state"
                      options={stateListFromCountry(country)}
                      placeholder="State"
                      required
                      requiredIndicator="*"
                      className="col-3"
                    />
                    <Field
                      component={Input}
                      name="postalCode"
                      required
                      requiredIndicator="*"
                      className="col-3"
                    />
                  </div>
                </FormSection>
              )}
            </div>
          </>
        ) : (
          <div className="group-block">
            <div className="payment-form-empty-cc">
              Credit Card is not required
            </div>
          </div>
        )}

        {showAutoRenew && (
          <>
            <hr className="divider" />
            <Field
              component={Checkbox}
              label="Automatic Renewal"
              name="autoRenewalAgreement"
              onChange={(autoRenewalAgreement) => {
                !autoRenewalAgreement && toggleAutoRenewalModalDisplay()
              }}
            />
            <p>
              Automatically renew my membership each year. I authorize Sea Tow
              to charge my credit card each year for my selected membership
              options at the then current retail value approximately 10 days
              prior to my membership renewal date and agree to the terms and
              conditions of the Sea Tow Automatic Renewal Program Agreement. I
              understand that prior to each renewal period an email will be sent
              informing me of the amount and date of the charge. I understand
              that I can opt out of Automatic Renewal at any time, or make
              change to my Automatic Renewal subscription, via my account
              management on seatow.com, calling{' '}
              <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> (
              {SEATOW_PHONE_DIGITS}) or by emailing{' '}
              <a href={`mailto:${SEATOW_INFO_CONTACT_EMAIL}`}>
                {SEATOW_INFO_CONTACT_EMAIL}
              </a>
              .
            </p>
          </>
        )}

        {showDonationForm && (
          <div
            aria-labelledby="category-header"
            role="radiogroup"
            className="group-block small-spacing inline-radio-list"
          >
            <label htmlFor="donationAmounts">
              Donate to the Sea Tow Foundation
            </label>
            <p>Be a part of making our waterways safer for all boaters.</p>
            <Field
              component={RadioGroup}
              id="donationAmounts"
              label={false}
              name="donationAmounts"
              onChange={() => change('otherDollarDonationAmount', null)}
              options={Object.values(DONATION_CONTRIBUTION_AMOUNTS)}
            />
            {donationAmounts === DONATION_CONTRIBUTION_AMOUNTS.OTHER && (
              <Field
                component={MaskedInput}
                label={false}
                name="otherDollarDonationAmount"
                normalize={parseNumberFromString}
                maskOptions={{
                  noImmediatePrefix: true,
                  numeral: true,
                  numeralPositiveOnly: true,
                  prefix: '$',
                }}
              />
            )}
          </div>
        )}

        <div className="button-group">
          <SubmitButton {...{ submitting }}>Continue to Review</SubmitButton>
        </div>
      </form>
    </>
  )
}

const beforeSubmit = (
  formValues,
  { discountCodeDetails, membership, showAutoRenew, totalPayment, },
) => {
  // Remove the formatting spaces from the credit expiration date
  const {
    autoRenewalAgreement,
    cardExpirationDate: rawCardExpirationDateString,
  } = formValues
  const cardExpirationDate = formatCreditCardExpirationDate(
    rawCardExpirationDateString,
  )
  const currentDate = new Date()
  const currentDateString = formatISODate(currentDate)

  const { usage_rules } = discountCodeDetails;
  if (!autoRenewalAgreement && usage_rules === USER_RULES.ARB_REQUIRED) {
    throw new SubmissionError({
      autoRenewalAgreement:
        'Automatic Renewal selection required to use this Activation Code.',
    })
  }

  if (!autoRenewalAgreement && totalPayment === 0) {
    return {
      ...formValues,
    }
  }

  if (
    !ccExpirationDateAfterProvidedDate(currentDateString, cardExpirationDate)
  ) {
    throw new SubmissionError({
      cardExpirationDate:
        'The credit card has expired. Please enter a valid credit card.',
    })
  }

  // Verify that the credit card expiration date is after the auto-renewal
  // date only if auto-renew is offered to the user.
  //
  // If New Member Applicant, calculate renewal date (renewal date would be +1
  // year from the current date the applicant signs up. Otherwise for
  // Existing Members (upgrades/renewals), use the membership expiration date
  // as renewal date
  if (showAutoRenew && autoRenewalAgreement) {
    const addYeartoCurrentDate = addYears(currentDate, 1)
    const { membership_expiration_date__c: expDate } = membership
    const renewalDateString = expDate ?? formatISODate(addYeartoCurrentDate)

    if (
      !ccExpirationDateAfterProvidedDate(renewalDateString, cardExpirationDate)
    ) {
      throw new SubmissionError({
        cardExpirationDate:
          'The credit card expiration date must be later than the membership renewal date. Please use a different credit card or unenroll in automatic renewal.',
      })
    }
  }

  // If the billing address was set to be the same as the shipping address,
  // set all of the billing address-related fields to null.
  const { billingAddressSameAsShipping } = formValues
  if (billingAddressSameAsShipping) {
    formValues.account.address = null
    formValues.account.city = null
    formValues.account.state = null
    formValues.account.postalCode = null
    formValues.account.country = null
  }

  return {
    ...formValues,
    cardExpirationDate,
  }
}

PaymentForm.propTypes = propTypes

function mapStateToProps(state) {
  return {
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'PaymentForm',
    constraints: {
      cardNumber: { presence: true, length: { minimum: 13 } },
      cardExpirationDate: {
        presence: true,
        length: { is: 5 },
      },
      securityCode: {
        presence: true,
        length: { minimum: 3, maximum: 4 },
        numericality: {
          message: 'can contain only numbers',
          onlyInteger: true,
        },
      },
      'account.address': ADDRESS_FORM_VALIDATOR,
    },
    initialValuesFilters: {
      reject: ['cardNumber', 'cardExpirationDate', 'securityCode'],
    },
  }),
  formValues(
    'autoRenewalAgreement',
    'billingAddressSameAsShipping',
    'donationAmounts',
    'otherDollarDonationAmount',
  ),
  formValues({ country: 'account.country' }),
  connect(mapStateToProps, null),
)(PaymentForm)
